<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-justify">
        <!-- Item 1 -->
        <h2 class="text-justify">CLÁUSULA PRIMEIRA – DO OBJETO</h2>
        <p>
          <b>1.</b> Constitui(em) objeto(s) do presente Contrato o(s) serviço(s)
          abaixo assinalado(s), cuja(s) descrição(ções) e/ou definições de
          caráter técnico completa(s) se encontra(m) no anexo
          <b>“DAS DEFINIÇÕES”,</b>.
        </p>
        <p>
          <b>1.1.</b> Todas as informações de caráter técnico mencionadas nesta
          Cláusula e que se demonstrem necessárias ao devido cumprimento e/ou
          composição de determinada(s) disposição(ções) contratual(is), estão
          previstas nas “DEFINIÇÕES” e, compõe(m) os direitos e deveres
          inerentes ao presente Contrato.
        </p>
        <p>
          <b>1.2.</b> São o(s) serviço(s) que compõe(m) o objeto do presente
          Contrato:
        </p>
        <h3>SMS e SMS MARKETING:</h3>
        <p>
          “SMS ou SMS Marketing” significam mensagens curtas de texto, a serem
          enviadas pela <b>CONTRATANTE</b>, através dos serviços de valor
          adicionado da <b>{{ whitelabelName }}</b> e integração às redes de telefonia móvel
          das Operadoras, para telefones celulares dos Usuários, que autorizam à
          <b>CONTRATANTE</b> a lhes enviar mensagens de cunho publicitário e/ou
          que visem apresentar-lhes produtos ou serviços da
          <b>CONTRATANTE</b> e/ou de quaisquer terceiros.
        </p>
        <h3>RCS:</h3>
        <p>
          RCS ou “Ritch Comunication Service” é um sistema de mensageria
          desenvolvido pelo Google e, é parte integrante do sistema padrão de
          mensagens dos smartphones com sistema operacional ANDROID. Consiste em
          um sistema de mensagens de texto, sem limite de caracteres, que
          suporta recursos adicionais como links, vídeos, imagens, cards, botões
          de ação, carrossel de imagens e integração com soluções Google.
        </p>
        <h3>WHATSAPP BUSINESS API:</h3>
        <p>
          Aplicativo de mensageria vinculado e de propriedade do Facebook que
          permite adicionar usuários diretamente vinculados à lista telefônica
          de um smartphone.
        </p>
        <h3>E-MAIL MARKETING:</h3>
        <p>
          Solução para envio de e-mails de forma massiva a partir de um arquivo
          CVS (Valores Separados por Vírgula) de contatos e/ou via integração. O
          “E-mail Marketing” permite que o usuário faça os envios e tenha acesso
          à relatórios sobre entregas, aberturas e cliques dentro da própria
          mensagem.
        </p>
        <h3>OMNI BUSINESS:</h3>
        <p>
          O OMNI BUSINESS é uma plataforma para atendimento de
          <b>CANAIS DIGITAIS</b> que permite ao operador/atendente selecionado
          pelo CONTRATANTE realizar atendimentos via <b>CHAT</b> de múltiplos
          canais simultaneamente e em uma única interface.
        </p>
        <h3>{{ whitelabelName }}BOT:</h3>
        <p>
          Os <b>{{ whitelabelName }}BOT’s</b> são ferramentas construídas para a
          automatização de atendimentos e processos, que podem ser associados
          aos canais digitais nativos da <b>{{ whitelabelName }}</b> (WhatsApp, RCS, Web
          Chat e SMS).
        </p>
        <p>
          A construção dos Bot’s é feita somente sob demanda e segue
          rigorosamente o processo que o <b>CONTRATANTE</b> desejar automatizar.
          A <b>{{ whitelabelName }}</b> compartilha sua experiência em melhores práticas
          para processos de fluxo estruturado buscando a melhor experiência do
          usuário final, mas em momento algum pode ser responsabilizada pelo
          insucesso na adesão ou conversão de um ChatBot por ela criado.
        </p>
        <p>
          <b>1.3.</b> As condições comerciais e técnicas referentes aos serviços
          descritos nesta Cláusula encontram-se descritos na Proposta Técnica e
          Comercial, sem prejuízo das condições descritas no presente Contrato e
          seu(s) respectivo(s) Anexo(s), as quais também são integralmente
          aplicáveis aos serviços descritos nesta Cláusula.
        </p>
        <p>
          <b>1.4.</b> Fica aqui estabelecido que quaisquer outras condições
          comerciais e/ou técnicas referentes aos serviços que àquelas
          estabelecidas/descritas na Proposta Técnica e Comercial não fazem
          parte do escopo dos serviços descritos no objeto desta Cláusula, de
          modo que quaisquer outros (serviços), leia-se, distintos, deverão ser
          incluídos através de Termos Aditivos ao presente Contrato.
        </p>
        <p>
          <b>1.5.</b> Considerando-se que a Proposta Técnica e Comercial
          descrita nesta Cláusula se caracteriza como subsídio e/ou complemento
          do presente Contrato, deverão ser devidamente assinadas pelo
          <b>CONTRATANTE</b> e a <b>{{ whitelabelName }}</b>, como condição de validade.
        </p>

        <!-- Item 2 -->
        <h2>
          CLÁUSULA SEGUNDA – DA PROPRIEDADE INTELECTUAL E USO DE MARCA E LOGO
        </h2>
        <p>
          <b>2.1.</b> As Partes poderão, por seu próprio risco, fazer uso da
          marca e logotipo uma da outra, leia-se, <b>CONTRATANTE</b> da
          <b>{{ whitelabelName }}</b> e <b>{{ whitelabelName }}</b> da <b>CONTRATANTE</b>, em seus
          respectivos portfólios, para fins de divulgação do objeto do presente
          Contrato em campanhas de mídia impressa, rádio, televisão, internet,
          fóruns, entre outros, desde que sejam cumpridas as regras de uso da
          marca de cada um dos contraentes.
        </p>
        <p>
          <b>2.2.</b> Para tanto, a <b>CONTRATANTE</b> ou <b>{{ whitelabelName }}</b> deverá
          encaminhar, previamente à efetiva publicação, o material a ser
          divulgado para a outra Parte, para fins de revisão e aprovação
          expressa das regras que foram utilizadas na exposição da marca e
          logotipo.
        </p>
        <p>
          <b>2.3.</b> O(s) material(is) descritos nesta Cláusula, serão
          oportunamente remetidos via e-mail pela parte interessada (<b
            >CONTRATANTE</b
          >
          ou <b>{{ whitelabelName }}</b>) através dos endereços eletrônicos descritos na
          Cláusula <b>“DA COMUNICAÇÃO”</b>, do presente Contrato.
        </p>
        <p>
          <b>2.4.</b> As regras inerentes ao uso da marca da Contratada são:
        </p>
        <p>
          <b>2.4.1.</b> As Partes contraentes concordam em não praticar qualquer
          ato ou omissão que prejudique os direitos da outra;
        </p>
        <p>
          <b>2.4.2.</b> Se comprometem, também, dentre outros, a não proceder ao
          registro de marca – qualquer que seja - de propriedade da outra
          (Parte) como complemento integrante de uma marca composta, ou, ainda,
          registrar qualquer marca que tenha o condão de gerar confusão em razão
          de sua similitude técnica-comercial.
        </p>
        <p>
          <b>2.5.</b> A <b>{{ whitelabelName }}</b> detém o direito, a titularidade e a
          propriedade dos seus serviços, inclusive quanto aos nomes de domínio,
          marcas, projetos gráficos, ícones, interfaces e outros elementos do
          negócio, sejam eles desenvolvidos anteriormente, durante e
          posteriormente à prestação dos serviços objeto deste Contrato. Sendo
          assim, todos os Direitos de Propriedade Intelectual inerentes aos
          serviços prestados, inclusive de exploração econômica sobre os
          softwares que os compõem, são, de propriedade intelectual da
          <b>{{ whitelabelName }}</b> ou licenciados a ela. Nada que esteja contido neste
          Contrato concede à <b>CONTRATANTE</b> ou aos Usuários qualquer título
          ou interesse de propriedade sobre qualquer Direito sobre a Propriedade
          Intelectual nos serviços prestados.
        </p>
        <p>
          <b>2.6.</b> No caso de a <b>CONTRATANTE</b> desrespeitar o que
          previsto nesta Cláusula, a que título o for, comissiva ou
          omissivamente, será responsabilizada pelo pagamento de todos os danos
          e/ou prejuízos decorrentes do uso indevido, que, por sua vez, serão
          determinados mediante liquidação prévia.
        </p>
        <p>
          <b>2.7.</b> No mesmo sentido, suportará com o pagamento e/ou reembolso
          de todos as custas e despesas, inclusive, judiciais e honorários
          advocatícios que a <b>{{ whitelabelName }}</b> venha a sofrer por conta da
          veiculação dos softwares que compõem o serviço prestado.
        </p>

        <!-- Item 3 -->
        <h2>CLÁUSULA TERCEIRA – DO PREÇO E DA FORMA DE PAGAMENTO</h2>
        <p>
          <b>3.1.</b> Pela prestação dos serviços descritos na Cláusula
          Primeira, deverá a <b>CONTRATANTE</b> pagar a <b>{{ whitelabelName }}</b> os
          valores conforme estabelecidos na Proposta Comercial de acordo com
          cada um dos serviços prestados.
        </p>
        <p>
          <b>3.1.1.</b> No que diz respeito ao serviço de <b>SMS</b>, o
          faturamento corresponderá ao valor unitário dividido pelo montante
          depositado na inserção de créditos, cuja cobrança se fundamentará nos
          seguintes status:
        </p>
        <p></p>
        <ul>
          <li>
            a) <b>DELIVERED</b> (entregue), de modo que o SMS encontrou
            destinatário válido e a mensagem consequentemente entregue;
          </li>
          <li>
            b) <b>UNDELIVERED</b> (indisponível), conquanto tenha a Operadora
            reconhecido o número como válido e ativo, não localizou o
            destinatário no período de 24 (vinte e quatro) horas, seja porque o
            destinatário está fora da área de cobertura; com o aparelho móvel
            desligado e/ou não configurado para o recebimento de mensagens,
            entre outros;
          </li>
          <li>
            c) <b>SENT</b> (enviado), de modo que a Operadora reconheceu o
            número como válido e ativo, contudo, não localizou o destinatário e
            permanecerá buscando pelo período de 24 (vinte e quatro) horas. Se
            localizado, o status será alterado para “Delivered”, contrariamente,
            para “Undelivered”.
          </li>
          <li>
            d) <b>ANSWER</b> (resposta), consistente na mensagem de resposta
            enviada pelo destinatário, ao passo que para o SMS Corporativo,
            segundo as normativas da ANATEL, o patrocínio dessa resposta é de
            obrigação do remetente.
          </li>
        </ul>
        <p></p>

        <p>
          <b>3.1.2.</b> No que diz respeito ao serviço de SMS, somente não
          haverá desconto de créditos no uso do serviço se o status corresponder
          a:
        </p>
        <p></p>
        <ul>
          <li>
            a) <b>FAILED</b> (falha), dado que se caracteriza somente pela
            tentativa de encontrar um número válido para que o envio seja
            efetivamente executado e concluído.
          </li>
        </ul>
        <p></p>

        <p>
          <b>3.2.</b> A <b>CONTRATANTE</b> tem ciência e concorda que, em caso
          de aumento dos custos da <b>{{ whitelabelName }}</b> em decorrência de aumento dos
          preços por parte da(s) Operadora(s) e/ou Parceiro(s) essenciais à
          prestação de serviço do presente Contrato, a <b>{{ whitelabelName }}</b> poderá
          reajustar os preços estabelecidos no Contrato, pelo procedimento
          denominado <b>“Reajuste dos Preços”</b>, abaixo descrito, de modo a
          restabelecer o equilíbrio econômico financeiro entre as Partes.
        </p>
        <p>
          <b>3.3.</b> O “Reajuste dos Preços” será informado através de
          Notificação a ser remetida para a <b>CONTRATANTE</b>, via e-mail nos
          endereços eletrônicos descritos na Cláusula <b>“DA COMUNICAÇÃO”</b>.
        </p>
        <p>
          <b>3.4.</b> O “Reajuste dos Preços” será efetivado em 30 (trinta) dias
          após o envio da Notificação. Caso a <b>CONTRATANTE</b> não concorde
          com o “Reajuste dos Preços”, deverá informar à {{ whitelabelName }} formalmente
          sobre a não concordância, sendo que, neste caso, o Contrato será
          considerado rescindido, sem qualquer penalidade para as Partes, após
          decorrido o lapso temporal, aqui, descrito.
        </p>
        <p>
          <b>3.4.1.</b> O serviço de <b>WHATS BUSINESS API</b>, de forma
          excepcional, se caracteriza pela vigência mínima de 6 (seis) meses de
          modo que, em razão de suas especificidades técnicas, demanda o
          pagamento de mensalidades não consumíveis.
        </p>
        <p>
          <b>3.5.</b> O silêncio da <b>CONTRATANTE</b>, quanto ao “Reajuste dos
          Preços” previsto na Cláusula 3.2, será considerando como concordância
          tácita e, portanto, como considerar-se-á como aceito.
        </p>
        <p>
          <b>3.6.</b> Os valores cobrados pelos serviços descritos na Cláusula
          1ª, poderão sofrer reajustes, no período de 12 (doze) meses, leia-se,
          a cada 12 (doze) meses, sendo que tal reajuste será feito com base no
          índice de variação do <b>IGP-M/FGV</b>, ou por outro que vier a
          substituí-lo mediante a concordância da {{ whitelabelName }}.
        </p>
        <!-- <p><b>3.6.1.</b> Na hipótese de substituição do índice de reajuste, o novo índice será determinado mediante Aditivo Contratual, devidamente firmado pelas Partes.</p> -->

        <!-- Item 4 -->
        <h2>CLÁUSULA QUARTA – DAS OBRIGAÇÕES DA CONTRATADA</h2>
        <p>
          <b>4.1.</b> Sem prejuízo das demais obrigações assumidas neste
          Contrato, a {{ whitelabelName }} obriga-se a:
        </p>
        <p>
          <b>4.2.</b> Repassar todas as informações geradas pela
          <b>CONTRATANTE</b> e inseridas nos sistemas da <b>{{ whitelabelName }}</b>,
          encaminhadas às respectivas Operadoras dos destinatários daquela, se,
          obrigando, ainda, a manter controle rígido de segurança para
          manutenção do sigilo e confidencialidade na transmissão dessas
          informações dentro da Área de Cobertura de sua Rede, leia-se, da
          <b>{{ whitelabelName }}</b>.
        </p>
        <p>
          <b>4.2.1.</b> Considerando-se que os envios são realizados única e
          exclusivamente pela <b>CONTRATANTE</b>, independentemente do meio,
          leia-se, (se) pela plataforma da <b>{{ whitelabelName }}</b> ou sistema próprio
          via integração, o controle do período utilizado é da
          <b>CONTRATANTE</b>, sem prejuízo do controle da <b>{{ whitelabelName }}</b> para
          fins de faturamento, pelo que esta não tem o dever de informar a
          <b>CONTRATANTE</b> acerca do número total de envios, senão, lhe
          remeter o respectivo faturamento.
        </p>
        <p>
          <b>4.3.</b> Não alterar o conteúdo das mensagens inseridas nos seus
          sistemas operacionais pela <b>CONTRATANTE</b>, transmitindo-as aos
          destinatários integralmente, tal qual, como recebidas pelos referidos
          sistemas e na forma escolhida pela <b>CONTRATANTE</b>.
        </p>
        <p>
          <b>4.4.</b> Executar todos os Serviços em conformidade com a Proposta
          Técnica e Comercial descritas Anexos que integram o presente Contrato.
        </p>
        <p>
          <b>4.5.</b> Emitir Nota Fiscal Fatura de Serviços, referente ao(s)
          serviço(s) prestado(s) pela <b>{{ whitelabelName }}</b>, no período do mês
          anterior à emissão da nota fiscal.
        </p>
        <p>
          <b>4.6.</b> Cumprir com o disposto à vista da excepcionalidade da não
          emissão de Nota Fiscal ao serviço de <b>SMS</b>, de modo que Serviço
          de Valor Adicionado – <b>SVA</b>.
        </p>
        <p>
          <b>4.7.</b> Prestar, sempre que solicitado pela <b>CONTRATANTE</b>, os
          esclarecimentos necessários sobre o desenvolvimento dos serviços
          descritos na Cláusula 1ª, bem como quaisquer outras informações
          concernentes à natureza e andamento dos serviços executados, ou em
          execução.
        </p>
        <p>
          <b>4.8.</b> Agir de acordo com a legislação, regulamentos e demais
          normas aplicáveis à prestação dos serviços objeto deste Contrato.
        </p>
        <p>
          <b>4.9.</b> Não será considerada responsabilidade da <b>{{ whitelabelName }}</b>,
          o atraso e/ou o descumprimento de quaisquer das obrigações contratuais
          na hipótese da ocorrência de caso fortuito ou de força maior. Neste
          caso, considerando-se a impossibilidade de cumprimento, a
          <b>{{ whitelabelName }}</b> deverá imediatamente informar a <b>CONTRATANTE</b> no
          endereço eletrônico descrito na Cláusula <b>“DA COMUNICAÇÃO”</b>, a
          ocorrência do respectivo evento.
        </p>
        <p>
          <b>4.10.</b> <b>{{ whitelabelName }}</b> não poderá, ainda, ser responsabilizada
          por qualquer resultado, negócio, serviço ou qualquer outra atividade
          não concretizada ou não realizada em virtude da indisponibilidade
          do(s) serviço(s) em virtude do que previsto na Cláusula anterior (4.9)
          do presente Contrato.
        </p>

        <!-- Item 5 -->
        <h2>CLÁUSULA QUINTA – DAS OBRIGAÇÕES DA CONTRATANTE</h2>
        <p>
          <b>5.1.</b> É expressamente vedada a <b>CONTRATANTE</b> reproduzir,
          extrair e/ou obter a cópia de quaisquer documentos técnicos, tais
          quais, como a Proposta Técnica, Proposta Comercial ou informações
          relacionadas ao funcionamento do sistema operacional e/ou das
          plataformas da proposta técnica, comercial ou qualquer informação
          relacionada ao funcionamento das plataformas da <b>{{ whitelabelName }}</b>, entre
          outros que, por alguma razão, integrem o presente Contrato.
        </p>
        <p>
          <b>5.2.</b> A <b>CONTRATANTE</b> se compromete expressamente a não
          alienar, ceder, locar, emprestar a terceiros, a título oneroso ou
          gratuito, a documentação e especificação técnica que compõe o(s)
          serviço(s) previstos na Cláusula 1ª, sob pena de responsabilidade,
          civil e criminal, além daquelas previstas na legislação específica de
          Proteção ao Direito Autoral de software, descritas na Lei 9.609/98,
          além sanções previstas no presente Contrato.
        </p>
        <p>
          <b>5.3.</b> A <b>CONTRATANTE</b> autoriza expressamente, para os
          devidos fins, que a <b>{{ whitelabelName }}</b> administre livremente seu negócio
          e sob seu nome, sem qualquer restrição, desde que adstrita as
          Cláusulas do presente Contrato.
        </p>
        <p>
          <b>5.4.</b> A <b>CONTRATANTE</b> concorda e se declara ciente de que o
          presente Contrato não se submete ao caráter de exclusividade, de modo
          que a <b>{{ whitelabelName }}</b> poderá dispor a terceiros da realização dos
          mesmos serviços e/ou similares, ora contratados, sem o dever de
          informá-la sobre a realização destes.
        </p>
        <p>
          <b>5.5.</b> Sem prejuízo das demais obrigações assumidas no presente
          Contrato, a <b>CONTRATANTE</b> se obriga a:
        </p>
        <p>
          <b>5.5.1.</b> Conservar com a máxima cautela e sigilo a senha de
          acesso aos sistemas de envio de mensagens, mantendo segredo sobre esta
          enquanto viger o presente Contrato. Em caso de desaparecimento, perda,
          furto, ou qualquer outra forma de apropriação da senha e do login por
          parte de terceiros não autorizados, deverá a <b>CONTRATANTE</b>, pelo
          que se compromete desde já a comunicar imediatamente a
          <b>{{ whitelabelName }}</b>, pelo endereço eletrônico descrito na Cláusula
          <b>“DA COMUNICAÇÃO”</b>.
        </p>
        <p>
          <b>5.5.2.</b> A senha de acesso aos sistemas de envio de mensagens
          será disponibilizada pela <b>{{ whitelabelName }}</b> através do e-mail
          determinado pela <b>CONTRATANTE</b> como administrador a ser informado
          quando da assinatura do presente Contrato ou a critério das Partes,
          através do endereço eletrônico constante da Cláusula
          <b>“DA COMUNICAÇÃO”</b>.
        </p>
        <p>
          <b>5.6.</b> Obter previamente e manter em sob seu controle, as
          autorizações expressas concedidas pelos destinatários que receberão as
          mensagens, isentando desde já a <b>{{ whitelabelName }}</b>, perante Operadoras e
          Usuários, de quaisquer responsabilidades decorrentes do envio de
          mensagens não autorizadas pelos Usuários.
        </p>
        <p>
          <b>5.7.</b> Abster-se de utilizar os serviços que compõe o presente
          Contrato para a execução de ações que tenham por objetivo a
          comercialização ou comunicação de produtos ou serviços de conteúdos
          e/ou interatividades, de qualquer natureza, assim como promoções e
          ações de mobile marketing.
        </p>
        <p>
          <b>5.7.1.</b> É expressamente vedado a execução das ações acima
          descritas, com a proibição de envio de mensagens, mesmo que gratuitas,
          quando não aprovadas previamente pelas Operadoras.
        </p>
        <p>
          <b>5.8.</b> Enviar mensagens que contenham obrigatoriamente, um meio
          para contato, para que, caso qualquer mensagem seja enviada errônea ou
          equivocadamente a telefones móveis de terceiros sem qualquer vínculo
          para com a <b>CONTRATANTE</b>, seja possível a estes destinatários
          cancelar o recebimento das mensagens <b>SMS</b> não solicitadas.
        </p>
        <p>
          <b>5.9.</b> Manter atualizadas todas as informações e documentos
          necessários à prestação dos serviços descritos na Cláusula 1ª, pela
          <b>{{ whitelabelName }}</b>.
        </p>
        <p>
          <b>5.10.</b> Disponibilizar profissionais com capacidade técnica
          adequada a operar os sistemas e softwares necessários para o
          cumprimento do objeto deste Contrato.
        </p>
        <p>
          <b>5.11.</b> Pagar os valores constantes das Notas Fiscais de
          Prestação de Serviços emitidas pela <b>{{ whitelabelName }}</b> em conformidade
          com o previsto no presente Contrato.
        </p>
        <p>
          <b>5.12.</b> Agir de acordo com a legislação, regulamentos e demais
          normas aplicáveis à prestação dos serviços objeto deste Contrato.
        </p>
        <p>
          <b>5.13.</b> A <b>CONTRATANTE</b> concorda, desde já, que a
          <b>{{ whitelabelName }}</b> não se responsabilizará e estará livre de qualquer
          ônus, caso a falha no envio de mensagens seja provocada por
          indisponibilidade no ambiente computacional e tecnológico da
          <b>CONTRATANTE</b>, devido a problemas no hardware ou, ainda, em caso
          de falha provocada por problemas de conectividade entre a
          <b>CONTRATANTE</b> e o servidor da <b>{{ whitelabelName }}</b>.
        </p>

        <!-- Item 6 -->
        <h2>CLÁUSULA SEXTA – DAS OBRIGAÇÕES MÚTUAS</h2>
        <p>
          <b>6.1.</b> A <b>CONTRATANTE</b> e a <b>{{ whitelabelName }}</b> são empresas
          independentes, e nenhuma disposição constante deste Instrumento deverá
          ser interpretada no sentido de sugerir a existência de um joint
          venture, ou qualquer tipo de participação societária, representação
          legal, agente legal, relação de emprego ou sociedade entre as Partes
          ou em seus nomes, sendo que, ambas as Partes são expressamente
          proibidas de assumir, estabelecer, criar ou dar origem a quaisquer
          obrigações, compromissos ou garantias em nome da outra, além daquelas
          estipuladas no presente Contrato.
        </p>
        <p>
          <b>6.2.</b> Na hipótese de, por qualquer motivo/razão, um Tribunal de
          jurisdição competente julgar qualquer disposição deste Contrato ou de
          seus Anexos, inexequível, sem prejuízo de sua validade, todos os
          demais dispositivos continuarão em pleno vigor e efeito, dado que
          deverão ser cumpridos de forma a concretizar o máximo possível à
          intenção das Partes.
        </p>
        <p>
          <b>6.3.</b> Na incidência de decisão jurisdicional adstrita ao que
          previsto na Cláusula anterior (6.2), a <b>CONTRATANTE</b> e
          <b>{{ whitelabelName }}</b> se comprometem desde já a negociar, no menor prazo
          possível - previsto através de Aditivo Contratual - em substituição à
          disposição anulada, a inclusão, neste Contrato, de outros termos e
          condições válidos que reflitam legalmente às disposições daquela,
          observados a intenção e o objetivo de ambas as Partes quando da
          negociação da referida Cláusula.
        </p>
        <p>
          <b>6.4.</b> As Partes, às suas custas, deverão indenizar e manter a
          outra Parte, seus diretores, agentes e empregados, isentos de qualquer
          responsabilidade inerente ao presente Contrato que, não tenha dado
          causa, bem como, se necessário o for defendê-los de qualquer ação
          originária de reclamação e/ou demanda passíveis de indenização,
          surgidas ou relacionadas à: (i) lesão pessoal ou dano à propriedade
          resultante, direta ou indiretamente, da violação de qualquer
          declaração ou garantia prestada pela Parte neste Contrato, incluindo
          seus Anexos; ou (ii) falta ou negligência de qualquer obrigação ou
          compromisso assumido pela Parte neste Contrato.
        </p>
        <p>
          <b>6.5.</b> Adstritos ao que disposto na Cláusula anterior (6.4), as
          Partes ficarão responsáveis apenas pelos danos diretamente e
          comprovadamente inerentes ao presente Contrato.
        </p>
        <p>
          <b>6.6.</b> Nenhuma Parte se responsabilizará perante a outra por
          quaisquer danos, independentemente de sua natureza, notadamente,
          indiretos, exemplares, especiais, incidentais ou punitivos, incluindo
          perda de utilização ou negócio perdido, receita, lucros ou clientela
          entre outros, resultantes ou relacionados ao presente Instrumento a
          que não tenha concorrido.
        </p>
        <p>
          <b>6.6.1.</b> A <b>{{ whitelabelName }}</b> não se responsabilizará pelos
          resultados comerciais ou relacionados pretendidos pela
          <b>CONTRATANTE</b>, através da contratação dos serviços previstos na
          Cláusula 1ª.
        </p>

        <!-- Item 7 -->
        <h2>CLÁUSULA SÉTIMA – DA CONFIDENCIALIDADE</h2>
        <p>
          <b>7.1.</b> As Partes ajustam entre si, em manter sigilosas todas as
          informações originárias da presente relação contratual, , parte
          integrante do presente Instrumento.
        </p>
        <p>
          <b>7.2.</b> Se comprometem, ainda, para fins de sigilo, por seus
          administradores, empregados, prepostos, a qualquer título, e
          comitentes.
        </p>
        <p>
          <b>7.3.</b> O descumprimento do que previsto nesta Cláusula importará
          na rescisão contratual, sem prejuízo da aplicabilidade de todas as
          sanções previstas neste Instrumento.
        </p>
        <p>
          <b>7.3.1.</b> Em qualquer hipótese, na responsabilidade por perdas e
          danos.
        </p>
        <p>
          <b>7.4.</b> Só serão legítimos como motivos de exceção à
          obrigatoriedade de sigilo, a ocorrência de descumprimento nas
          seguintes hipóteses:
        </p>
        <p>
          <b>7.4.1.</b> A informação já era conhecida anteriormente às
          tratativas do negócio jurídico;
        </p>
        <p>
          <b>7.4.2.</b> Houve prévia e expressa anuência dos Partícipes, quanto
          à liberação da obrigação de sigilo e confidencialidade;
        </p>
        <p>
          <b>7.4.3.</b> A informação foi comprovadamente obtida por outra fonte,
          de forma legal e legítima, independentemente do presente instrumento
          jurídico;
        </p>
        <p>
          <b>7.4.4.</b> Determinação judicial e/ou governamental para
          conhecimento das informações, desde que notificada imediatamente as
          Operadoras, previamente à liberação, e sendo requerido segredo de
          justiça no seu trato judicial e/ou administrativo.
        </p>

        <!-- Item 8 -->
        <h2>CLÁUSULA OITAVA – DA PROTEÇÃO DE DADOS</h2>
        <p>
          <b>8.1.</b> As Partes, por si e por seus colaboradores, obrigam-se a
          atuar no presente Contrato em conformidade com a Legislação vigente
          sobre Proteção de Dados Pessoais e as determinações de órgãos
          reguladores/fiscalizadores sobre a matéria, em especial a Lei Geral de
          Proteção de Dados nº 13.709/2018, denominada “LGPD”, além das demais
          normas e políticas de proteção de dados de cada país onde houver
          qualquer tipo de tratamento dos dados de seus respectivos clientes, o
          que inclui os dados dos clientes desta, observando-se in totum as
          disposições contidas no Anexo III, parte integrante deste Instrumento.
          No manuseio dos dados a <b>CONTRATADA</b> deverá:
        </p>
        <p>
          <b>8.1.1.</b> Tratar os dados pessoais a que tiver acesso apenas de
          acordo com as instruções da <b>CONTRATANTE</b> e/ou {{ whitelabelName }} e, em
          conformidade com as disposições contidas neste Contrato.
        </p>
        <p>
          <b>8.1.2.</b> Manter e utilizar medidas de segurança técnicas e
          administrativas efetivas, compreenda-se, apropriadas e suficientes
          para proteger a confidencialidade de todos os dados pessoais mantidos
          ou consultados/transmitidos eletronicamente, assegurando a proteção
          desses dados contra acesso não autorizado, destruição, uso,
          modificação, divulgação ou perda acidental ou indevida.
        </p>
        <p>
          <b>8.1.3.</b> Acessar os dados dentro de seu escopo e na medida
          abrangida por sua permissão de acesso (autorização) e que os dados
          pessoais não podem ser lidos, copiados, modificados ou removidos sem
          autorização expressa e por escrito da Parte correspondente.
        </p>
        <p>
          <b>8.1.4.</b> Garantirem, por si próprias ou quaisquer de seus
          empregados, prepostos, sócios, diretores, representantes ou terceiros
          contratados, a confidencialidade dos dados processados, assegurando
          que todos os seus colaboradores prepostos, sócios, diretores,
          representantes ou terceiros contratados que lidam com os dados
          pessoais sob suas responsabilidades, nos termos da Cláusula 7ª.
        </p>
        <p>
          <b>8.1.5.</b> Manter quaisquer dados pessoais estritamente
          confidenciais e não os utilizar para outros fins, com exceção da
          prestação de serviços objeto deste Contrato.
        </p>

        <!-- Item 9 -->
        <h2>
          CLÁUSULA NONA – DA VIGÊNCIA DO CONTRATO E DA RESCISÃO CONTRATUAL
        </h2>
        <p>
          <b>9.1.</b> Com exceção dos serviços de <b>WHATSAPP BUSINESS API</b> e
          <b>RCS</b>, descritos na Cláusula 1ª, todos os demais serviços possuem
          vigência, por prazo <b>indeterminado</b>, com renovação automática
          mensal, sendo sua eficácia, na modalidade pré-paga, condicionada à
          aquisição de créditos, salvo disposição em contrário por meio de
          Aditivo Contratual.
        </p>
        <p>
          <b>9.1.1.</b> Com relação aos serviços de
          <b>WHATSAPP BUSINESS API</b> e <b>RCS</b>, o Contrato terá vigência
          mínima de <b>6 (seis) meses</b>, com renovação automática, sendo esta,
          na hipótese da modalidade pré-paga, condicionada à aquisição de
          créditos.
        </p>
        <p>
          <b>9.2.</b> Assiste às Partes a faculdade de rescindir o presente
          Contrato injustificadamente sem qualquer penalização. Para tanto,
          deverá notificar a outra pelo endereço eletrônico descrito na Cláusula
          <b>“DA COMUNICAÇÃO”</b>, com antecedência mínima de 30 (trinta) dias,
          sem prejuízo do pagamento referente mês de uso dos serviços descritos
          na Cláusula 1ª e das mensalidades remanescentes relacionadas ao
          <b>WHATSAPP BUSINESS API</b> e <b>RCS</b>.
        </p>
        <p>
          <b>9.3.</b> A rescisão motivada pelo descumprimento de quaisquer das
          disposições contidas neste Contrato, por culpa de qualquer das Partes,
          implicará no pagamento de multa correspondente ao total dos prejuízos
          apurados mediante liquidação prévia, a ser informado pelo endereço
          eletrônico descrito na Cláusula <b>“DA COMUNICAÇÃO”</b>, acrescidos de
          20% (vinte por cento), com exceção do que previsto nas Cláusulas
          11.6.2, “e”, inerente ao serviço <b>RCS</b> e 11.6.3, “i”, relacionada
          ao serviço <b>WHATSAPP BUSINESS API</b>, ocasião em que, se o
          descumprimento se der nos termos das referidas Cláusulas, prevalecerão
          as multas nelas previstas.
        </p>

        <!-- Item 10 -->
        <h2>
          CLÁUSULA DÉCIMA – DO SERVIÇO FORA DO ESCOPO CONTRATADO (PROJETOS
          ESPECIAIS)
        </h2>
        <p>
          <b>10.1.</b> Qualquer serviço adicional/projeto especial deverá
          constar em adendo através de Aditivo Contratual, de modo que, firmado
          pelas Partes, passará a integrar o presente Contrato.
        </p>

        <!-- Item 11 -->
        <h2>CLÁUSULA DÉCIMA PRIMEIRA – DAS DISPOSIÇÕES GERAIS</h2>
        <p>
          <b>11.1.</b> O presente Contrato juntamente com seus Anexos,
          constituem o entendimento único e integral entre a
          <b>CONTRATANTE</b> e a <b>{{ whitelabelName }}</b> com relação ao seu objeto e
          substituem todos os demais instrumentos, acordos, cartas de intenção e
          entendimentos anteriores entre as Partes com relação a esse objeto.
        </p>
        <p>
          <b>11.2.</b> Independente do disposto acima, na hipótese de a
          <b>{{ whitelabelName }}</b> ser penalizada pelas Operadoras ou terceiros pelo
          descumprimento das obrigações pela <b>CONTRATANTE</b>, esta deverá
          ressarcir a <b>{{ whitelabelName }}</b> de quaisquer gastos e/ou despesas
          incorridas, incluindo, mas não se limitando a perdas e danos, multas a
          que a <b>{{ whitelabelName }}</b> for condenada, bem como honorários advocatícios
          que dispensar para se defender, entre outros, sem prejuízo da
          resolução deste Contrato, que serão previamente liquidadas.
        </p>
        <p>
          <b>11.3.</b> Na hipótese de a <b>{{ whitelabelName }}</b> comprovadamente
          rescindir o Contrato com as Operadoras que viabilizam os serviços
          objeto deste Contrato, ou se eventualmente o serviço vier a ser
          proibido por determinação judicial e/ou administrativa, este Contrato
          poderá ser rescindido, independentemente de qualquer formalidade
          legal, pelo que deverá a <b>{{ whitelabelName }}</b> comunicar tal fato a
          <b>CONTRATANTE</b>. Neste caso, as Partes estarão liberadas do
          pagamento de qualquer multa e ou indenização, ficando ressalvados os
          pagamentos devidos até o evento, os quais deverão ser quitados em
          tempo e forma.
        </p>
        <p>
          <b>11.4.</b> Na hipótese da Cláusula anterior (11.3), os créditos não
          utilizados, adquiridos anteriores ao fato que rescindir o Contrato,
          não poderão ser objeto de regresso.
        </p>
        <p>
          <b>11.5.</b> As disposições gerais previstas neste Contrato serão
          alteradas, quando necessário e por vontade das Partes, mediante
          Aditivo Contratual pelas condições previstas no Anexo II, ocasião em
          que aquelas prevalecerão sobre estas.
        </p>
        <p>
          <b>11.6.</b> Individualizando-se cada um dos serviços descritos na
          Cláusula 1ª e que compõe o objeto do presente Contato, são as
          disposições gerais específicas.
        </p>
        <p><b>11.6.1.</b> SMS ou SMS MARKETING</p>
        <p>
          <b>a)</b> O conteúdo das mensagens SMS a serem enviadas pela
          <b>CONTRATANTE</b> à <b>{{ whitelabelName }}</b> é de exclusiva responsabilidade
          da <b>CONTRATANTE</b>. Sendo assim, a <b>CONTRATANTE</b> será
          responsável integralmente pelos conteúdos enviados via mensagens,
          abstendo-se de enviar mensagens com conteúdo que possibilitem a
          apresentação e/ou identificação de:
        </p>
        <p></p>
        <ul>
          <li>1. Conteúdo eleitoral ou político;</li>
          <li>2. Empresas de telefonia celular;</li>
          <li>
            3. Empresa cuja atividade social seja similar à exercida pelas
            operadoras de telefonia celular, incluindo qualquer anúncio ou
            oferta de produtos e/ou serviços dessas empresas;
          </li>
          <li>
            4. Divulgação de quaisquer tipos de ideologias, incluindo, mas não
            se limitando a, motivações políticas, religiosas, correntes de
            pensamento, e outras;
          </li>
          <li>
            5. Quaisquer outros conteúdos que possam ser caracterizados como
            spam.
          </li>
        </ul>
        <p></p>
        <p>
          <b>b)</b> A <b>CONTRATANTE</b> se responsabiliza integralmente,
          eximindo desde já a <b>{{ whitelabelName }}</b> de qualquer responsabilidade
          solidária ou subsidiária em eventuais reivindicações, obrigando-se a
          arcar com todas as despesas e/ou penalidades de qualquer natureza que
          eventualmente sejam impostas à <b>{{ whitelabelName }}</b>, incluindo perdas e
          danos e honorários advocatícios, devido à inobservância das condições
          estabelecidas no <b>Item a) da Cláusula 14.6.1</b>, para a prestação
          de serviços a que se refere o presente Contrato.
        </p>
        <p>
          <b>c)</b> Uso Proibido. A <b>CONTRATANTE</b> não utilizará os serviços
          para criar, transmitir, distribuir, disseminar, publicar ou armazenar:
          (i) qualquer material que esteja violando qualquer lei aplicável,
          regra ou regulamentação, tais como o Código de Defesa do Consumidor
          e/ou Normas de Regulamentação do CONAR, que sejam consideradas
          Mensagens SMS infundadas (“hoax”), inclusive qualquer lei ou
          regulamentação de privacidade, que seja falso ou leve a interpretações
          dúbias; (ii) qualquer material difamatório, discriminatório, racista,
          abusivo, obsceno (tais como pedofilia), indecente, falso ou acintoso,
          mesmo que seja relativo a partidos políticos e suas doutrinas ou a
          candidatura de pessoas a postos eletivos públicos; ou material que
          ameace ou encoraje prejuízo físico, promova o terrorismo, a destruição
          de propriedade ou infração ou desapropriação dos direitos legais de
          qualquer Parte, incluindo quaisquer Direitos sobre Propriedade
          Intelectual, de Imagem e/ou a criação e envio de Serviços de Valor
          Agregado não solicitadas (SPAM) ou infundados ou mesmo que contenham
          graves erros de digitação e/ou ortografia; (iii) faça apologia a
          drogas e ao tráfico de drogas; (iv) ofendam as leis, a moral, a ética
          e os bons costumes; (vi) seja de alguma forma proibido ou não
          recomendável a determinada faixa etária, salvo se solicitado ou
          autorizado previamente, por escrito, pelas Operadoras, que serão as
          responsáveis pela criação do canal de informação no qual a mesma
          deverá ser veiculada, de forma diferenciada; (vii) façam apologia aos
          entorpecentes, cigarros, bebidas alcoólicas; (viii) incentive a
          participação em jogos ilegais; (ix) que mencionem qualquer tipo de
          propaganda de empresas concorrentes de cada uma das Operadoras,
          filiais, coligadas, controladas ou controladoras ou contenham menção à
          ANATEL, empresas de telefonia móvel ou fixa, à própria Operadora, ou
          informações referentes aos serviços de telecomunicações; (x) tenha
          conotação publicitária, promocional ou de propaganda, salvo aquelas
          pré-autorizadas pelas Operadoras.
        </p>
        <p>
          <b>d)</b> Para os fins deste Contrato, a prática do SPAM somente se
          caracterizará quando o envio de mensagem não solicitada/não autorizada
          ocorrer por dolo e má-fé do agente, ou seja, a prática do SPAM tiver
          sido pretendida ou intencionada pelo agente que o realizou.
        </p>
        <p>
          <b>e)</b> No que se refere os envios a <b>{{ whitelabelName }}</b> não se
          responsabiliza pelo não recebimento de mensagens, seja qual for a
          forma de envio, em virtude da ocorrência de qualquer fato ou situação
          que impeça tal atividade, tais como: ausência ou degradação de
          cobertura, permanente ou temporária, falha em equipamentos das
          Operadoras, falha de energia ou transmissão, ou em decorrência de
          bloqueios do serviço de telefonia móvel, destinatário em área de
          cobertura analógica, destinatário com aparelho de telefonia móvel
          desligado ou destinatário fora da área de cobertura das Operadoras,
          destinatário portador de estação móvel analógica ou qualquer outra
          impossibilidade técnica, que seja de responsabilidade das Operadoras.
          A <b>{{ whitelabelName }}</b> deverá, todavia, informar imediatamente a
          <b>CONTRATANTE</b>, sempre que quaisquer mensagens não forem entregues
          no prazo de 24 (vinte e quatro) horas.
        </p>
        <p>
          <b>f)</b> A <b>CONTRATANTE</b> está ciente de que toda e qualquer
          mensagem enviada, que ultrapassar o número de caracteres permitido
          pelo modelo do aparelho do Usuário e/ou pela Operadora, incluindo o
          cabeçalho, será interrompida e encerrada. Caso a
          <b>CONTRATANTE</b> assim deseje, a Mensagem SMS poderá ser dividida e
          enviada à continuação por meio de novas Mensagens SMS.
        </p>
        <p>
          <b>g)</b> Caso a <b>CONTRATANTE</b> opte por revender o serviço
          referente à Mensagens SMS prestados pela <b>{{ whitelabelName }}</b> no âmbito
          deste Contrato a seus próprios clientes, a <b>CONTRATANTE</b> ficará
          responsável em fazer com que os adquirentes do serviço cumpram, de
          maneira integral, com todos os termos e condições relacionados a tal
          serviço. Qualquer descumprimento por parte de qualquer cliente da
          <b>CONTRATANTE</b> a tais termos e condições será considerado como um
          descumprimento da própria <b>CONTRATANTE</b>, sujeitando-a a todas as
          penalidades previstas no Contrato e na legislação aplicável.
        </p>
        <p>
          <b>h)</b> A <b>CONTRATANTE</b> se responsabiliza pela obtenção junto
          aos Usuários de prévio consentimento (“Opt-in”) para envio e
          recebimento de SMS Marketing, obrigando-se a manter cópia do Opt-in do
          Usuários destinatários das SMS Marketing, bem como dos cancelamentos
          formais (“Opt-out”), isentando integralmente a <b>{{ whitelabelName }}</b> de
          quaisquer responsabilidades, seja de ordem solidária ou subsidiária,
          quanto à inexistência de Opt-in do Usuário ou fraudes dessa natureza.
        </p>
        <p>
          <b>i)</b> Em caso de utilização de caixa de seleção (<b>“Check Box”</b
          >) para indicar o Opt-in do Usuário, é obrigatória a clareza na
          fraseologia do Check Box sobre a autorização para recebimento de SMS
          Marketing. Não será admissível que a Check Box seja apresentada ao
          Usuário com a opção de aceite previamente assinalada, seja por website
          ou wapsite.
        </p>
        <p>
          <b>j)</b> Caso a <b>CONTRATANTE</b> não tenha Opt-in do Usuário, a
          <b>CONTRATANTE</b> deverá, obrigatoriamente, excluir o Usuário da base
          de clientes autorizados para o recebimento de SMS Marketing, ficando a
          <b>CONTRATANTE</b> responsável por reparar as perdas e danos
          comprovadamente causados à <b>{{ whitelabelName }}</b>, inclusive aquelas
          relativas às multas e sanções em caso de infração a esta cláusula.
        </p>
        <p>
          <b>k)</b> Toda e qualquer sanção pecuniária eventualmente aplicada à
          <b>{{ whitelabelName }}</b> em processos administrativos ou judiciais em razão da
          impossibilidade de comprovação do Opt-in do Usuário pela
          <b>CONTRATANTE</b> será integralmente repassada e cobrada da
          <b>CONTRATANTE</b> pela <b>{{ whitelabelName }}</b>.
        </p>
        <p>
          <b>l)</b> A <b>CONTRATANTE</b> deverá manter as informações de Opt-in
          e Opt-out armazenadas por 06 (seis) anos contados a partir da data da
          efetivação do Opt-in ou Opt-out do Usuário, bem como deverá
          disponibilizar outros meios de comunicação, como por exemplo e-mail,
          call center, entre outros, para que o Usuário manifeste o seu Opt-out
          e cancele a autorização de recebimento de SMS Marketing da
          <b>CONTRATANTE</b>.
        </p>
        <p>
          <b>m)</b> O pedido de Opt-out pelo Usuário deve ser atendido
          imediatamente pela <b>CONTRATANTE</b>, devendo sistematicamente ser
          efetivado em até 24 (vinte e quatro) horas.
        </p>
        <p>
          <b>n)</b> Quando o Usuário manifestar o seu Opt-out por qualquer das
          formas previstas acima, a Contratante deverá enviar uma mensagem SMS
          ao Usuário confirmando o seu descadastramento. Caso este Usuário não
          possua Opt-in na base de clientes autorizados para o recebimento de
          SMS Marketing da <b>CONTRATANTE</b>, quando do seu pedido de Opt-out,
          a <b>CONTRATANTE</b> deverá enviar uma mensagem SMS informando que o
          Usuário não faz mais parte de sua base de clientes autorizados para o
          recebimento de SMS Marketing.
        </p>
        <p>
          <b>o)</b> Caso o Usuário manifeste seu Opt-out diretamente às
          Operadoras ou à <b>{{ whitelabelName }}</b>, estas poderão, a seus exclusivos
          critérios, incluir o Usuário em suas “black lists” para evitar o
          recebimento de SMS Marketing da <b>CONTRATANTE</b>.
        </p>
        <p>
          <b>p)</b> Todas as mensagens SMS Marketing da <b>CONTRATANTE</b> aos
          Usuários, bem como aquelas mensagens SMS enviadas do Usuário à
          <b>CONTRATANTE</b> em função do recebimento por este das SMS Marketing
          da <b>CONTRATANTE</b>, deverão ser gratuitas para os Usuários e
          custeadas pela <b>CONTRATANTE</b>.
        </p>
        <p>
          <b>q)</b> Em caso de SMS Marketing da <b>CONTRATANTE</b> do tipo
          “compre e ganhe”, a <b>CONTRATANTE</b> deverá ter disponível um
          website, wapsite ou número de telefone, onde os Usuários tenham acesso
          a todos os termos e condições da referida campanha/promoção.
        </p>
        <p>
          <b>r)</b> A <b>CONTRATANTE</b> declara e reconhece estar ciente que a
          tentativa de envio de SMS Marketing que não estiverem em conformidade
          com as disposições aqui contidas poderá ser automaticamente bloqueada
          ou cancelada pela Tecnologia da <b>{{ whitelabelName }}</b>, sem a necessidade de
          qualquer aviso prévio e aplicação de penalidade à <b>{{ whitelabelName }}</b>.
        </p>
        <p>
          <b>s)</b> A possibilidade de bloqueio ou cancelamento do envio de SMS
          Marketing que viole as disposições aqui contidas: (i) não obriga a
          <b>{{ whitelabelName }}</b> a fazê-lo; (ii) não gera qualquer responsabilidade à
          <b>{{ whitelabelName }}</b> pelo conteúdo da SMS Marketing da <b>CONTRATANTE</b>;
          (iii) não implica em qualquer tipo de aprovação ou validação dos
          conteúdo da SMS Marketing da <b>CONTRATANTE</b> pela <b>{{ whitelabelName }}</b>;
          (iv) não desobriga a <b>CONTRATANTE</b> do dever de não enviar SMS
          Marketing que violem as disposições deste Anexo; (v) não exime a
          <b>CONTRATANTE</b> de qualquer responsabilidade prevista neste
          Contrato.
        </p>
        <p>
          <b>t)</b> A <b>{{ whitelabelName }}</b> não poderá ser responsabilizada por
          qualquer resultado, negócio, serviço ou qualquer outra atividade não
          concretizada ou não realizada em virtude do não recebimento da SMS
          Marketing pelos Usuários, conforme determinado nos itens antecedentes.
        </p>
        <p>
          <b>u)</b> A <b>CONTRATANTE</b> não poderá criar, transmitir,
          distribuir, disseminar, publicar, armazenar ou enviar SMS Marketing
          aos Usuários que possibilitem a apresentação e/ou identificação de:
        </p>
        <p></p>
        <ul>
          <li>1. Conteúdo falso ou leve a interpretações dúbias;</li>
          <li>
            2. Conteúdos de jogos e/ou brincadeiras que não indiquem a sua
            natureza de forma destacada e efusiva;
          </li>
          <li>
            3. Conteúdos que indiquem frequência de tarifação diferente da
            efetivamente aplicada, por exemplo, valor diário quando a assinatura
            é cobrada por semana;
          </li>
          <li>
            4. Conteúdos que não indiquem valores na forma numérica, e sim por
            extenso, indicando a moeda brasileira e mencionando seu sinal (R$);
          </li>
          <li>
            5. Conteúdo que invada a privacidade de terceiros ou prejudique-os
            de alguma forma, nos termos da legislação vigente ou em acordo com
            decisão administrativa ou judicial;
          </li>
          <li>
            6. Conteúdo que promova, sob alguma forma, o racismo contra grupos
            de minorias, ou qualquer forma de fanatismo político ou religioso,
            discriminando grupos de pessoas ou etnias;
          </li>
          <li>
            7. Conteúdo que seja obsceno, tais como pornografia, pedofilia,
            etc.;
          </li>
          <li>
            8. Conteúdo que viole direitos de terceiros, incluindo, mas não se
            limitando aos direitos de propriedade intelectual;
          </li>
          <li>
            9. Conteúdo a Usuários que não tenham manifestado o seu OPT-IN de
            forma prévia, expressa e por escrito, nos termos desse Anexo, ou
            seja, conteúdo não solicitado pelo Usuário (SPAM) ou infundados;
          </li>
          <li>
            10. Conteúdo que seja de alguma forma proibido ou não recomendável a
            determinada faixa etária a que se destine;
          </li>
          <li>
            11. Conteúdos de bebidas alcoólicas para Usuários com idade inferior
            a 18 (dezoito) anos completos;
          </li>
          <li>
            12. Conteúdo que faça apologia às drogas e/ou ao tráfico de drogas;
          </li>
          <li>
            13. Conteúdos que divulguem quaisquer tipos de ideologias,
            incluindo, mas não se limitando a motivações políticas, religiosas,
            correntes de pensamento, e outras;
          </li>
          <li>
            14. Conteúdo que ofenda as Normas legais ou infra legais, a moral, a
            ética e aos bons costumes;
          </li>
          <li>
            15. Conteúdo publicitário de qualquer empresa que seja concorrente
            da Operadora que o Usuário seja assinante ou <b>CONTRATANTE</b>;
          </li>
          <li>
            16. Conteúdo publicitário de Operadora distinta daquela que o
            Usuário seja assinante ou <b>CONTRATANTE</b>;
          </li>
          <li>
            17. Qualquer conteúdo que induza à migração dos Usuários para outra
            Operadora de telefonia móvel ou fixa que não aquela à qual o
            respectivo Usuário seja assinante ou <b>CONTRATANTE</b>; e
          </li>
          <li>
            18. Qualquer conteúdo que tenha como objeto produtos ou serviços das
            Operadoras, exceto quando previamente autorizado pela respectiva
            Operadora.
          </li>
        </ul>
        <p></p>
        <p><b>11.6.2.</b> RCS</p>
        <p>
          <b>a)</b> O conteúdo das mensagens RCS a serem enviadas pela
          <b>CONTRATANTE</b> à <b>{{ whitelabelName }}</b> é de exclusiva responsabilidade
          da <b>CONTRATANTE</b>. Sendo assim, a <b>CONTRATANTE</b> será
          responsável integralmente pelos conteúdos enviados via mensagens,
          abstendo-se de enviar mensagens com conteúdo que possibilitem a
          apresentação e/ou identificação de:
        </p>
        <p></p>
        <ul>
          <li>1. Conteúdo eleitoral ou político;</li>
          <li>2. Empresas de telefonia celular;</li>
          <li>
            3. Empresa cuja atividade social seja similar à exercida pelas
            operadoras de telefonia celular, incluindo qualquer anúncio ou
            oferta de produtos e/ou serviços dessas empresas;
          </li>
          <li>
            4. Divulgação de quaisquer tipos de ideologias, incluindo, mas não
            se limitando a, motivações políticas, religiosas, correntes de
            pensamento, e outras;
          </li>
          <li>
            5. Quaisquer outros conteúdos que possam ser caracterizados como
            spam.
          </li>
        </ul>
        <p>
          <b>b)</b> A <b>CONTRATANTE</b> se responsabiliza incondicionalmente,
          eximindo desde já a <b>{{ whitelabelName }}</b> de qualquer responsabilidade
          solidária ou subsidiária em eventuais reivindicações, obrigando-se a
          arcar com todas as despesas e/ou penalidades de qualquer natureza que
          eventualmente sejam impostas à <b>{{ whitelabelName }}</b>, incluindo perdas e
          danos e honorários advocatícios, devido à inobservância das condições
          estabelecidas no <b>Item a) desta Cláusula</b>, para a prestação de
          serviços a que se refere o presente Contrato.
        </p>
        <p>
          <b>c)</b> Uso Proibido. A <b>CONTRATANTE</b> não utilizará os Serviços
          para criar, transmitir, distribuir, disseminar, publicar ou armazenar:
          (i) qualquer material que esteja violando qualquer lei aplicável,
          regra ou regulamentação, tais como o Código de Defesa do Consumidor
          e/ou Normas de Regulamentação do CONAR, que sejam consideradas
          mensagens infundadas (“hoax”), inclusive qualquer lei ou
          regulamentação de privacidade, que seja falso ou leve a interpretações
          dúbias; (ii) qualquer material difamatório, discriminatório, racista,
          abusivo, obsceno (tais como pedofilia), indecente, falso ou acintoso,
          mesmo que seja relativo a partidos políticos e suas doutrinas ou a
          candidatura de pessoas a postos eletivos públicos; ou material que
          ameace ou encoraje prejuízo físico, promova o terrorismo, a destruição
          de propriedade ou infração ou desapropriação dos direitos legais de
          qualquer <b>Parte</b>, incluindo quaisquer Direitos sobre Propriedade
          Intelectual, de Imagem e/ou a criação e envio de Serviços de Valor
          Agregado não solicitadas (SPAM) ou infundados ou mesmo que contenham
          graves erros de digitação e/ou ortografia; (iii) faça apologia a
          drogas e ao tráfico de drogas; (iv) ofendam as leis, a moral, a ética
          e os bons costumes; (vi) seja de alguma forma proibido ou não
          recomendável a determinada faixa etária, salvo se solicitado ou
          autorizado previamente, por escrito, pelas Operadoras, que serão as
          responsáveis pela criação do canal de informação no qual a mesma
          deverá ser veiculada, de forma diferenciada; (vii) façam apologia aos
          entorpecentes, cigarros, bebidas alcoólicas; (viii) incentive a
          participação em jogos ilegais; (ix) que mencionem qualquer tipo de
          propaganda de empresas concorrentes de cada uma das Operadoras,
          filiais, coligadas, controladas ou controladoras ou contenham menção à
          ANATEL, empresas de telefonia móvel ou fixa, à própria Operadora, ou
          informações referentes aos serviços de telecomunicações; (x) tenha
          conotação publicitária, promocional ou de propaganda, salvo aquelas
          pré-autorizadas pelas Operadoras.
        </p>
        <p>
          <b>d)</b> Para os fins deste Contrato, a prática do SPAM somente se
          caracterizará quando o envio de mensagem não solicitada/autorizada
          ocorrer por dolo e má-fé do agente, ou seja, a prática do SPAM tiver
          sido pretendida ou intencionada pelo agente que o realizou.
        </p>
        <p>
          <b>e)</b> No que se refere aos envios, a <b>{{ whitelabelName }}</b> não se
          responsabiliza pelo não recebimento de mensagens, seja qual for a
          forma de envio, em virtude da ocorrência de qualquer fato ou situação
          que impeça tal atividade, tais como: ausência ou degradação de
          cobertura, permanente ou temporária, falha em equipamentos das
          Operadoras, falha de energia ou transmissão, ou em decorrência de
          bloqueios do serviço de telefonia móvel, destinatário em área de
          cobertura analógica, destinatário com aparelho de telefonia móvel
          desligado ou destinatário fora da área de cobertura das Operadoras,
          destinatário portador de estação móvel analógica ou qualquer outra
          impossibilidade técnica, que seja de responsabilidade das Operadoras.
          A <b>{{ whitelabelName }}</b> deverá, todavia, informar imediatamente a
          <b>CONTRATANTE</b>, sempre que quaisquer mensagens não forem entregues
          no prazo de 24 (vinte e quatro) horas.
        </p>
        <p>
          <b>f)</b> A <b>CONTRATANTE</b> está ciente de que o envio do RCS está
          sujeito à disponibilidade de destinatário com usuário de RCS
          devidamente ativado para sua efetiva entrega e que as mensagens
          enviadas que não forem entregues não serão tarifadas.
        </p>
        <p>
          <b>g)</b> Caso a <b>CONTRATANTE</b> opte por revender o serviço
          referente à Mensagens RCS prestados pela <b>{{ whitelabelName }}</b> no âmbito
          deste Contrato à seus próprios clientes, a <b>CONTRATANTE</b> ficará
          responsável em fazer com que os adquirentes do serviço cumpram, de
          maneira integral, com todos os termos e condições aqui descritos
          relacionados a tal serviço. Qualquer descumprimento por parte de
          qualquer cliente da <b>CONTRATANTE</b> à tais termos e condições será
          considerado como um descumprimento da própria <b>CONTRATANTE</b>,
          sujeitando-a à todas as penalidades previstas no presente Contrato e
          na legislação aplicável.
        </p>
        <p>
          <b>h)</b> A ferramenta RCS é um produto de posse e propriedade
          intelectual de Google LLC, constituída e organizada de acordo com as
          leis do estado de Delaware, EUA, e operada de acordo com as leis dos
          EUA. O serviço de mensagens RCS e Chat RCS tem seu desenvolvimento e
          sustentação feita pela Jibe Mobile, Inc. localizada em 1600
          Amphitheatre Parkway, Mountain View, CA 94043 EUA, exceto no Espaço
          Econômico Europeu e na Suíça. Nesses países, o Serviço é fornecido
          pela Jibe Mobile Limited, localizada em 70 Sir John Rogerson’s Quay,
          Dublin 2, Irlanda.
        </p>
        <p>
          <b>i)</b> Portanto, a assinatura do presente Contrato, implica no
          conhecimento e aceite dos termos e condições de uso de soluções Google
          conforme abaixo:
        </p>
        <ul>
          <li>
            1. Termos de serviço do Google:
            <a href="https://policies.google.com/terms?hl=pt-BR" target="_blank"
              >https://policies.google.com/terms?hl=pt-BR</a
            >
          </li>
          <li>
            2. Política de Privacidade do Google:
            <a
              href="https://policies.google.com/privacy?hl=pt-BR"
              target="_blank"
              >https://policies.google.com/privacy?hl=pt-BR</a
            >
          </li>
          <li>
            3. Termos de Serviço do Jibe Mobile:
            <a href="https://jibe.google.com/policies/terms/" target="_blank"
              >https://jibe.google.com/policies/terms/</a
            >
          </li>
        </ul>
        <p></p>

        <h2><b>11.6.3.</b> WHATS BUSINESS API</h2>
        <p>
          <b>a)</b> A <b>CONTRATANTE</b> está ciente de que toda e qualquer
          mensagem enviada, que não obedeça aos padrões impostos pelo WhatsApp
          Business Api, ou não esteja nos formatos aceitáveis, será interrompida
          e encerrada.
        </p>
        <ul>
          <li>
            1. Todas as mensagens enviadas devem ser enviadas no formato de HSM
            (Formato de mensagem autorizada pelo WhatsApp Business Api e que
            necessita ser solicitada e configurada pela <b>{{ whitelabelName }}</b>), sendo
            que a solicitação deverá ser feita através da própria plataforma.
          </li>
          <li>
            2. O prazo para resposta da <b>{{ whitelabelName }}</b> é de 10 (dez) dias
            úteis, contados da data do recebimento da solicitação.
          </li>
          <li>
            3. A <b>{{ whitelabelName }}</b> não se responsabiliza por mensagens não
            aprovadas pela equipe do WhatsApp Business Api, sendo que, para
            mensagens não aprovadas, o processo descrito acima deverá ser
            reiniciado.
          </li>
          <li>
            4. Não é permitido o envio de nenhuma mensagem de cunho promocional,
            político, vendas ou marketing, entre outros casos específicos e
            mencionados nos termos de uso e políticas do WhatsApp Business Api,
            sob pena de desativação imediata da conta da <b>CONTRATANTE</b> em
            caso de descumprimento desta vedação.
          </li>
        </ul>
        <p>
          <b>b)</b> A <b>CONTRATANTE</b> está ciente de que esta ferramenta
          poderá ser encerrada imediatamente, sem aviso prévio, pelo detentor do
          aplicativo WhatsApp Business Api. Neste caso, o serviço previsto neste
          Anexo deixará de ser prestado imediatamente após tal encerramento pelo
          detentor do aplicativo WhatsApp Business Api. Para tanto, a
          <b>{{ whitelabelName }}</b> se compromete a enviar uma notificação escrita à
          <b>CONTRATANTE</b> informando o encerramento do serviço.
        </p>
        <p>
          <b>c)</b> É necessária a solicitação do Opt-in da base para a qual
          será enviada as mensagens através do WhatsApp Business Api. Sugerimos:
          Opt-in por SMS, Opt-in no Contrato com o cliente e Opt-in no
          formulário que o cliente preenche deixando o telefone.
        </p>
        <p>
          <b>d)</b> Com relação ao WhatsApp Business Api é expressamente
          proibido o envio de mensagens de marketing ou spam para os clientes,
          mesmo os que estiverem na lista de Opt-ins.
        </p>
        <p>
          <b>e)</b> A <b>CONTRATANTE</b>, ao contratar/ativar o serviço de
          WhatsApp Business Api, declara que leu, entendeu e está de acordo com
          todas as condições, direitos e obrigações previstas nos termos e
          políticas do Facebook/WhatsApp Business Api, conforme a lista a
          seguir, mas não se limitando a:
        </p>
        <ul>
          <li>
            1. WhatsApp Business Api Business Policy -
            <a
              href="https://www.whatsapp.com/legal/business-policy/"
              target="_blank"
              >https://www.whatsapp.com/legal/business-policy/</a
            >
          </li>
          <li>
            2. WhatsApp Business Api Business Solution Policy -
            <a
              href="https://www.whatsapp.com/legal/business-solution-policy/"
              target="_blank"
              >https://www.whatsapp.com/legal/business-solution-policy/</a
            >
          </li>
          <li>
            3. WhatsApp Business Api Business Solution Terms -
            <a
              href="https://www.whatsapp.com/legal/business-solution-terms/"
              target="_blank"
              >https://www.whatsapp.com/legal/business-solution-terms/</a
            >
          </li>
          <li>
            4. WhatsApp Business Api Business Terms of Service -
            <a
              href="https://www.whatsapp.com/legal/business-terms/"
              target="_blank"
              >https://www.whatsapp.com/legal/business-terms/</a
            >
          </li>
          <li>
            5. WhatsApp Business Api Business Data Processing Terms -
            <a
              href="https://www.whatsapp.com/legal/business-data-processing-terms/"
              target="_blank"
              >https://www.whatsapp.com/legal/business-data-processing-terms/</a
            >
          </li>
          <li>
            6. WhatsApp Business Api Intellectual Property Policy -
            <a href="https://www.whatsapp.com/legal/ip-policy" target="_blank"
              >https://www.whatsapp.com/legal/ip-policy</a
            >
          </li>
        </ul>
        <p>
          <b>f)</b> A <b>CONTRATANTE</b> declara sua total, ilimitada e
          irrestrita aceitação à todos os termos e políticas do
          Facebook/WhatsApp Business Api referentes ao serviço de WhatsApp
          Business Api (incluindo, sem limitação, as descritas acima), sendo que
          declara estar totalmente ciente também de que termos e políticas
          poderão ser incluídos, alterados, adaptados e/ou atualizados pelo
          Facebook/WhatsApp Business Api, a qualquer tempo e a seu critério,
          ficando, portanto, a <b>CONTRATANTE</b> obrigada a verificar
          periodicamente os termos e políticas de modo a estar totalmente ciente
          e cumprir integralmente com suas condições, direitos e obrigações.
        </p>
        <p>
          <b>g)</b> A <b>CONTRATANTE</b> concorda e confirma que não poderá, em
          nenhuma circunstância e de nenhuma forma, apresentar-se para Usuários,
          clientes e/ou quaisquer terceiros como detentora da conexão com o
          Facebook/WhatsApp Business Api, uma vez que tal conexão é da
          <b>{{ whitelabelName }}</b>, e não da <b>CONTRATANTE</b>. Por este motivo, a
          <b>CONTRATANTE</b> está proibida de fazer qualquer ato promocional,
          marketing e/ou propaganda, seja interna ou externa, de qualquer
          natureza, em que mencione ser a detentora desta conexão, devendo,
          sempre que desejar fazer algum destes atos, mencionar, expressamente,
          que esta conexão é da <b>{{ whitelabelName }}</b>, e não da <b>CONTRATANTE</b>.
        </p>
        <p>
          <b>h)</b> Tendo em vista o acima exposto, a <b>CONTRATANTE</b> deverá
          enviar à <b>{{ whitelabelName }}</b>, de maneira prévia, todo e qualquer material
          que desejar divulgar ou utilizar referente à WhatsApp Business Api
          para que a <b>{{ whitelabelName }}</b> verifique tal material e, caso esteja de
          acordo e desde que respeitada a proibição aqui descrita, autorizar à
          <b>CONTRATANTE</b> a utilizar tal(is) material(is).
        </p>

        <h2>11.6.4. E-MAIL MARKETING</h2>
        <p>
          a) A utilização do “E-mail Marketing”, conquanto não se submeta ás
          regras específicas, deve-se ater aos limites descritos no presente
          Contrato.
        </p>

        <h2>11.6.5. {{ whitelabelName }} Chat</h2>
        <p>
          <b>b)</b> A plataforma {{ whitelabelName }} Chat, oferece recursos para atendimento de
          forma nativa e possui escalabilidade para funcionalidades sob demanda
          que podem, ou não, caracterizar novos projetos.
        </p>
        <p>
          <b>c)</b> Atendimento ativo, mediante disparo de campanhas por
          integração e/ou diretamente pela importação de arquivo de dados. Em
          momento algum é permitido que o operador/atendente faça o disparo de
          campanhas.
        </p>
        <p>
          <b>d)</b> Atendimento receptivo se dará através da integração de
          múltiplos canais, onde o operador/atendente poderá realizar múltiplos
          atendimentos simultaneamente independentemente do canal que o cliente
          estiver utilizando. Os canais que não possuírem integração nativa com
          a plataforma, poderão ser associados por desenvolvimento avulso.
        </p>
        <p>
          <b>e)</b> O <b>CONTRATANTE</b> fica ciente de que novos
          desenvolvimentos de sua parte serão de sua integral responsabilidade e
          custos, bem como novos desenvolvimentos que forem demandados ao
          CONTRATADO serão previamente discutidos e alinhados mediante a
          apresentação de uma nova proposta de SERVIÇO PROFISSIONAL para a sua
          implementação.
        </p>
        <p>
          <b>f)</b> Dashboard gerencial com parametrização dos módulos de
          atendimento. A plataforma permite o monitoramento em relatório das
          conversas realizadas entre os operadores/atendentes e os clientes,
          segregando inclusive por canal.
        </p>
        <p>
          <b>g)</b> Integração de diversos canais e plataformas. É possível
          integrar a plataforma de atendimento {{ whitelabelName }} Chat com canais
          conversacionais e sistemas já existentes no <b>CONTRATANTE</b> para
          importação de dados pré e durante atendimento e exportação de dados
          pós atendimento. Esse recurso depende da compatibilidade de APIs entre
          as duas plataformas e desenvolvimentos que forem necessários para essa
          funcionalidade podem acarretar novos custos de projeto conforme grau
          de complexidade que serão discutidos, mapeados e entregues em forma de
          proposta comercial para sua realização.
        </p>
        <p>
          <b>h)</b> Possibilidade de integração com Inteligência artificial ou
          fluxo estruturado (árvore de decisão), mesmo que de terceiros. Com
          isso o <b>CONTRATANTE</b> poderá implementar uma camada de atendimento
          anterior ao atendimento humano. Esse recurso depende da
          compatibilidade de APIs entre as duas plataformas e desenvolvimentos
          que forem necessários para essa funcionalidade podem acarretar novos
          custos de projeto conforme grau de complexidade que serão discutidos,
          mapeados e entregues em forma de proposta comercial para sua
          realização.
        </p>

        <h2>11.6.6. {{ whitelabelName }} BOT</h2>
        <p>
          <b>a)</b> A construção dos BOT’s segue um processo de mapeamento de
          design com a participação do <b>CONTRATANTE</b> que irá analisar e
          validar as informações e fluxos previamente à construção do BOT. Toda
          e qualquer alteração que for necessária após a entrega do BOT será
          considerado um novo desenvolvimento podendo, inclusive, resultar em
          novos custos a serem apresentados por proposta comercial.
        </p>
        <p>
          <b>b)</b> Sobre o mapeamento e processo de validação de BOT para sua
          construção:
        </p>
        <p>
          <b>c)</b> A <b>{{ whitelabelName }}</b> realizará 1 (uma) reunião, ou quantas
          forem necessárias, até o total entendimento do processo que o
          <b>CONTRATANTE</b> desejar automatizar via BOT. O
          <b>CONTRATANTE</b> tem a responsabilidade de compartilhar as
          informações necessárias ao entendimento do processo em sua totalidade.
          Essas informações estão protegidas quanto à sua confidencialidade
          conforme o Anexo IV (Acordo de Confidencialidade) deste Contrato.
        </p>
        <p>
          <b>d)</b> Concluída a etapa de mapeamento dos processos, a
          <b>{{ whitelabelName }}</b> entregará ao <b>CONTRATANTE</b> um design visual do
          fluxo referente o BOT a ser construído para sua validação. Integrações
          com sistemas terceiros que forem necessários estarão mencionados nesse
          design e os custos e responsabilidades estarão descritos na proposta
          comercial.
        </p>
        <p>
          <b>e)</b> A entrega do BOT se dará conforme prazo estipulado na
          proposta comercial e devidamente aceita pelo <b>CONTRATANTE</b>.
        </p>
        <p>
          <b>f)</b> A <b>{{ whitelabelName }}</b> não se responsabiliza pelo insucesso de
          adoção do BOT pelos Usuários do <b>CONTRATANTE</b>, bem como não é
          responsável pela forma de linguagem, palavreado e/ou expressões que o
          <b>CONTRATANTE</b> deseje implementar e que possam causar mal
          entendimento e/ou interpretação dos usuários finais.
        </p>
        <p>
          <b>g)</b> Existe a possibilidade de integração com Inteligência
          artificial (NLP) e, nesse caso, somente tecnologias de terceiros, cuja
          contratação, manutenção e sustentação é de total responsabilidade do
          <b>CONTRATANTE</b>.
        </p>

        <h2>11.6.7. PLATAFORMA {{ whitelabelName }}</h2>
        <p>
          <b>a)</b> A plataforma e/ou sistema <b>{{ whitelabelName }}</b>, compreende-se
          como uma solução CpaaS (Plataform as a Service) por onde a
          <b>CONTRATANTE</b> e nomeados a seu critério terão acesso às
          informações gerenciais de sua conta, relatórios de uso, controle de
          usuários e que engloba, ou pode englobar, todas as soluções descritas
          no presente Contrato.
        </p>

        <p>
          <b>11.7.</b> À vista do que disposto nesta Cláusula, a
          <b>CONTRATANTE</b> se compromete, principalmente, em empenhar todos os
          esforços necessários para substituir a <b>{{ whitelabelName }}</b>, quando
          necessário, no polo passivo de eventuais demandas judiciais ajuizadas
          contra esta e que sejam decorrentes da inobservância das Cláusulas e
          condições expressas no presente Contrato, não sendo, para tanto,
          devido qualquer reembolso e/ou ressarcimento àquela.
        </p>
        <p>
          <b>11.7.1.</b> Na hipótese de qualquer responsabilidade recair sobre a
          <b>{{ whitelabelName }}</b>, deverá a <b>CONTRATANTE</b> ressarci-la,
          considerando-se, notadamente, quando decorrente do descumprimento
          desta Cláusula.
        </p>
        <p>
          <b>11.7.2.</b> O descumprimento do que disposto nas Cláusulas 11.6.1,
          11.6.2, 11.6.3, 11.6.4, 11.6.5 e 11.6.6, sujeitará a
          <b>CONTRATANTE</b> ao pagamento de todas as penalidades impostas pelas
          Operadoras, sem prejuízo das perdas e danos decorrentes, conforme
          regras determinadas pelas próprias Operadoras.
        </p>

        <p>
          <b>11.8.</b> A <b>CONTRATANTE</b> reconhece expressamente sua total e
          exclusiva responsabilidade pelo conteúdo inerentes aos serviços
          descritos na Cláusula 1ª e delimitado nas Cláusulas 11.6.1, 11.6.2,
          11.6.3, 11.6.4, 11.6.5 e 11.6.6, por ela enviadas aos Usuários e pela
          utilização dos serviços da <b>{{ whitelabelName }}</b>, obrigando-se a arcar com
          toda e qualquer despesa, prejuízo e penalidade, além de perdas e
          danos, incluindo-se às sanções previstas no presente Contrato.
        </p>
        <p>
          <b>11.8.1.</b> Adstrita a Cláusula anterior, quando houver prejuízos
          às Operadoras, independentemente de sua natureza, em função de
          qualquer inobservância dos serviços que compõem o objeto do presente
          Contrato, será de responsabilidade da <b>CONTRATANTE</b> a
          substituição da <b>{{ whitelabelName }}</b>, bem como das Operadoras em eventuais
          demandas administrativas e/ou judiciais, conforme os termos e
          condições previstos nesse Contrato.
        </p>

        <p>
          <b>11.9.</b> Independentemente de outras obrigações ou
          responsabilidades previstas nesta Cláusula 11, a <b>CONTRATANTE</b> se
          responsabiliza integralmente, eximindo desde já a <b>{{ whitelabelName }}</b> de
          qualquer responsabilidade solidária ou subsidiária em eventuais
          reivindicações, obrigando-se a arcar com todas as despesas e/ou
          penalidades de qualquer natureza que eventualmente sejam impostas,
          pelas Operadoras, pelo Poder Judiciário, ou pelos Órgãos da
          Administração Pública, direta ou indireta, como por exemplo ANATEL ou
          PROCON, incluindo-se perdas e danos, além dos respectivos honorários
          advocatícios contratuais e de sucumbência que sejam incorridos, devido
          à inobservância das condições aqui estabelecidas.
        </p>

        <p>
          <b>11.10.</b> Caso a <b>CONTRATANTE</b> deixe de cumprir com o
          disposto na Cláusula “DAS DISPOSIÇÕES GERAIS”, sem prejuízo de demais
          penalidades previstas no presente Contrato e na legislação aplicável,
          ficará obrigada a pagar à <b>{{ whitelabelName }}</b> multa não compensatória no
          valor de R$ 500.000,00 (quinhentos mil reais), além de todas as perdas
          e danos que a <b>{{ whitelabelName }}</b> incorrer em razão de tal descumprimento,
          ficando responsável também por reembolsar todos os custos que a
          <b>{{ whitelabelName }}</b> inerentes em nos termos da Cláusula anterior.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalContentTerms',
  type: String,
  computed: {
    whitelabelName() {
      return this.$store.state.admin.whitelabel.name;
    },
  },
};
</script>
