<template>
  <div class="form-group">
    <label
      class="control control-outline control-primary control--checkbox"
      :class="{ invalid: !isValid }"
    >
      {{ label }}
      <slot></slot>
      <input type="checkbox" :value="value" @change="handleChange" />
      <div class="control__indicator"></div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxComponent',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    isValid() {
      return !this.error;
    },
  },
  methods: {
    handleChange(event) {
      if (!this.isValid) {
        this.validate(event.target.checked);
      }
      this.$emit('input', event.target.checked);
    },
    validate(value) {
      this.error = false;
      if (this.required && !value) {
        this.error = true;
      }
    },
    checkValidation() {
      this.validate(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.control.control-outline {
  &.invalid {
    color: #ff5c75;
    .control__indicator {
      background: #ff5c75;
    }
  }
  .control__indicator {
    border: 2px solid var(--clr-yup-purple);
    height: 18px;
    width: 18px;
  }
  input:checked ~ .control__indicator {
    border: 2px solid var(--clr-yup-purple);
    background: var(--clr-yup-purple) !important;
    &::after {
      top: 2px;
      left: 5px;
      width: 5px;
      height: 9px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
    }
  }
}
.form-group {
  margin-bottom: 26px;
}
</style>
